@import '../custom/variables';

// .control-label {
//     font: Roboto;
//     font-weight: 400 !important;
//     color:$text-color-label!important;
// }

.color-text-primary {
    color: $text-color-titulo !important;
}

.color-text-secondary {
    color: $text-color-subtitulo;
}

.color-text-tertiary {
    color: $text-color-titulo-base;
}

.color-text-error {
    color: #B02A37;
}

.color-text-blue {
    color: #0B57D0;
}

.color-text-black {
    color: #000 !important;
}

.carddash-title {
    font-family: 'Roboto', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: calc(7px + 0.5vw);
    line-height: 23px;
    color: $text-color-titulo;
}



.text-resposive {
    font-size: calc(5.5px + 0.3vw);
}

.flex-direction-between {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
}

.carddash {
    box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    // margin-bottom: 15px;
}

.text-animed {
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
        transform: translateX(2px) translateY(-4px);
        opacity: 0.8;
    }
}

.cardShadow {
    box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.075);
}


.cardLabelTemporal {
    border: 1px solid #0B57D0;
    border-radius: 99px;
    background: #FFFFFF;
    color: #0B57D0;
    text-align: center;
    font-size: calc(5px + 0.35vw);
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    line-height: 120%;
    padding: 5px 10px;
    margin-right: 8px;
    cursor: pointer;
}

.cardLabelTemporal.active {
    background: #CFE2FF;
    color: #0B57D0;
    border: 1px solid #0B57D0;
}

.font-20-responsive {
    font-size: calc(5px + 0.6vw);
}

.font-16-responsive {
    font-size: calc(5px + 0.5vw);
}

.font-14-responsive {
    font-size: calc(5px + 0.4vw);
    color: #6C757D;
}

.font-12-responsive {
    font-size: calc(5px + 0.35vw);
    color: #3A4249;
}

.font-24-responsive {
    font-size: calc(8px + 0.6vw);
    color: #3A4249;
}

.cursor-pointer {
    cursor: pointer !important;
}

@media screen and (min-width: 1920px) {
    .font-20-responsive {
        font-size: 20px;
    }

    .font-16-responsive {
        font-size: 16px;
    }

    .font-14-responsive {
        font-size: 14px;
    }

    .font-12-responsive {
        font-size: 12px;
    }

    .cardLabelTemporal {
        font-size: 14px;
    }

    .font-24-responsive {
        font-size: 24px;
    }

}