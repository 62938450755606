
.logo-image {
    max-width: 300px !important;
}

#main-wrapper[data-layout='vertical'] .topbar .top-navbar .navbar-header[data-logobg='skin4'],
#main-wrapper[data-layout='horizontal'] .topbar .top-navbar .navbar-header[data-logobg='skin4'] {
    background: #4d4f5c;
}

#main-wrapper[data-layout='vertical'] .topbar .navbar-collapse[data-navbarbg='skin4'],
#main-wrapper[data-layout='vertical'] .topbar[data-navbarbg='skin4'],
#main-wrapper[data-layout='horizontal'] .topbar .navbar-collapse[data-navbarbg='skin4'],
#main-wrapper[data-layout='horizontal'] .topbar[data-navbarbg='skin4'] {
    background: #4d4f5c;
}

.logo-header {
    max-width: 165px; /* Define a largura máxima da logo */
    position: fixed; /* Define a posição fixa */
    top: 18px; /* Define a distância superior a partir do topo da janela de visualização */
   left: 45px;
   z-index: 999;
}

.icon-header {
    max-width: 30px;
}
.sidebar-nav ul .sidebar-item.selected > .sidebar-link {
    background: #4d4f5c !important;
}
.sidebar-link:hover {
    color: #4d4f5c !important;
}
#main-wrapper[data-layout='vertical']
    .left-sidebar[data-sidebarbg='skin6']
    .sidebar-nav
    ul
    .sidebar-item.selected
    > .sidebar-link:hover
    i {
    color: #fff !important;
}
#main-wrapper[data-layout='vertical']
    .left-sidebar[data-sidebarbg='skin6']
    .sidebar-nav
    ul
    .sidebar-item
    .first-level
    .sidebar-item.selected
    > .sidebar-link {
    background: transparent !important;
    color: #4d4f5c !important;
}
#main-wrapper[data-layout='vertical']
    .left-sidebar[data-sidebarbg='skin6']
    .sidebar-nav
    ul
    .sidebar-item.selected
    > .sidebar-link:hover {
    color: #fff !important;
}
#main-wrapper[data-layout='vertical'] .left-sidebar[data-sidebarbg='skin6'] .sidebar-nav ul .sidebar-item .sidebar-link:hover i {
    color: #4d4f5c !important;
}

/* ------------- BUTTON */
iframe {
    pointer-events: none;
}

.btn-round-acoes {
    width: 30px !important;
    height: 30px !important;
    line-height: 0px;
    color: #fff !important;
    border-radius: 100% !important;
}

.btn-primary {
    color: #fff;
    background-color: #007bff !important;
    border-color: #007bff !important;
    border-radius: 4px !important;
}

.btn-primary:hover {
    color: #fff;
    background-color: #0069d9!important;
    border-color: #0069d9!important;
    font-weight: bold;
}

.btn-primary:not(:disabled):not(.disabled):active,
.btn-primary:not(:disabled):not(.disabled).active,
.show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #df6b00;
    border-color: #df6b00;
}

.btn-info {
    color: #fff;
    background-color: #014e84;
    border-color: #014e84;
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
}

.btn-info:hover {
    color: #fff;
    background-color: #014e84;
    border-color: #014e84;
}

.btn-info:not(:disabled):not(.disabled):active,
.btn-info:not(:disabled):not(.disabled).active,
.show > .btn-info.dropdown-toggle {
    color: #fff;
    background-color: #014e84;
    border-color: #014e84;
}

.btn-warning {
    color: #fff;
    background-color: #ffca0b;
    border-color: #ffca0b;
    box-shadow: 0 4px 6px rgb(50 50 93 / 11%), 0 1px 3px rgb(0 0 0 / 8%);
}

.btn-warning:hover {
    color: #fff;
    background-color: #ffca0b;
    border-color: #ffca0b;
}

.btn-warning:not(:disabled):not(.disabled):active,
.btn-warning:not(:disabled):not(.disabled).active,
.show > .btn-warning.dropdown-toggle {
    color: #fff;
    background-color: #ffca0b;
    border-color: #ffca0b;
}

/* ------------- BG - BACKGROUND */
.bg-blue {
    background-color: #007bff !important;
    color: #fff !important;
}

.bg-orange {
    background-color: #ff8c22 !important;
}

.bg-cyan {
    background-color: #007bff !important;
    color: #fff !important;
}

/* ------------- TABLE */

.ReactTable .rt-th,
.ReactTable .rt-td {
    white-space: normal !important;
}

/* ------------- CARD */

.title-card-md {
    font-size: 14px !important;
}

body {
    font-size: 0.775rem;
    background-color: #EEF5F9 !important;
    overflow-y: auto !important;
}

.hover-link-state:hover {
    cursor: pointer;
}
.hover-link-state:hover #titleFilter {
    opacity: 0.75;
}

/* ----------- FONTS */

.bold {
    font-weight: bold;
}

/* ----------- BADGES */
.badge-green {
    color: #fff;
    background-color: #7fb81b;
}
.badge-info[href]:hover,
.badge-info[href]:focus {
    color: #fff;
    text-decoration: none;
    background-color: #7fb81b;
}

/* ------------ Menu SSO */
.logo-sso {
    max-width: 150px;
}

.mailbox .drop-title:after {
    background: initial;
}
#loading_login {
    position: relative;
    margin-left: 170px;
    margin-bottom: 20px;
}
