.input-x-small {
    width: 128px !important;
}

.input-small {
    width: 280px !important;
}

.input-medium {
    width: 430px !important;
}

.input-large {
    width: 584px !important;
    max-width: 584px;
}