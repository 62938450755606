// ======================================================
// Custom layout variables
// ======================================================
@import url('https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700');
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

// ======================================================
// Theme Global setting colors
// ======================================================

$themecolor: #4d4f5c;
$themecolor-dark: #4d4f5c;
$themecolor-alt: #26c6da;
$theme-light: #fff;
$theme-dark: #212529;

$skin1: #272c33;
$skin2: #00acc1;
$skin3: #fc4b6c;
$skin4: #4d4f5c;
$skin5: #0b57d0;
$skin6: #fff;

// ======================================================
// Topbar settings
// ======================================================

$topbar: $theme-light;
$topbar-height: 40px;
$topbar-navlink-padding: 10px 15px;
$topbar-navlink-font-size: 19px;
$topbar-navlink-height: 70px;
$topbar-navbrand-padding: 0 10px 0 10px;
$topbar-shadow: 1px 0px 7px rgba(0, 0, 0, 0.05);
$topbar-mailbox-width: 300px;
$topbar-mailbox-height: 317px;
$topbar-droptitle-bg: url(../../../../assets/images/background/img5.png) no-repeat;

// ======================================================
// Authentication backgrounds
// ======================================================

$errorbg: url(../../../../assets/images/background/error-bg.jpg) no-repeat center center #fff;

$profile-bg: url(../../../../assets/images/background/user-info.jpg);

// For Form Material
$danger-img: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='../../../../assets/images/icon/danger.svg'");
$warning-img: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='../../../../assets/images/icon/warning.svg'");
$success-img: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='../../../../assets/images/icon/success.svg'");

// ======================================================
// Sidebar settings
// ======================================================
// sidebar type
$sidebar-width-full: 240px;
$sidebar-width-iconbar: 180px;
$sidebar-width-mini: 46px;
$left-part-width: 260px;

// sidebar text color settings
$sidebar: $theme-light;
$sidebar-alt: #e8eff0;
$sidebar-text: #fff;
$sidebar-icons: #fff;
$sidebar-text-dark: #607d8b;
$sidebar-icons-dark: #000;

// ======================================================
// boxed layout setting
// ======================================================
$boxed-width: 1200px;

//text color titulo/subtitulo stting
$text-color-titulo:#3A4249;
$text-color-subtitulo:#6C757D;
$text-color-titulo-base:#152536;

// ======================================================
// Extra style setting
// ======================================================
$shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
$transitions: 0.2s ease-in;
$transparent-dark-bg: rgba(0, 0, 0, 0.03);
$lft: left;
$rgt: right;
$card-alt: #e4e9ef;
$color-primary: #1e5d96;

// ======================================================
// Mixin setting
// ======================================================
%square {
    border-radius: 0px;
}

%rotate45 {
    transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -webkit-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
}

%topbar-shadow {
    box-shadow: $topbar-shadow;
    -webkit-box-shadow: $topbar-shadow;
    -o-box-shadow: $topbar-shadow;
    -moz-box-shadow: $topbar-shadow;
}

%card-shadow {
    box-shadow: $shadow;
    -webkit-box-shadow: $shadow;
    -moz-box-shadow: $shadow;
    -o-box-shadow: $shadow;
}

// ======================================================
// Bootstrap override colors
// ======================================================

$white: #fff !default;
$gray-100: #f6f9fc !default;
$gray-200: #e9ecef !default;
$gray-300: #dee2e6 !default;
$gray-400: #ced4da !default;
$gray-500: #adb5bd !default;
$gray-600: #8898aa !default;
$gray-700: #525f7f !default;
$gray-800: #263238 !default;
$gray-900: #455a64 !default;
$black: #000 !default;
$cyan: #4fc3f7 !default;

$blue: #1e88e5 !default;
$indigo: #7460ee !default;
$purple: #6f42c1 !default;
$red: #fc4b6c !default;
$orange: #fd7e14 !default;
$yellow: #ffb22b !default;
$green: #21c1d6 !default;

$blue-link: #0b57d0 !default;
$light-blue-200: #e5eff8 !default;
$dark-red: #b02a37 !default;
$light-cyan-100: #b7e7fa !default;

$colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$colors: map-merge(
    (
        'blue': $blue,
        'indigo': $indigo,
        'purple': $purple,
        'red': $red,
        'orange': $orange,
        'yellow': $yellow,
        'green': $green,
        'cyan': $cyan,
        'white': $white,
    ),
    $colors
);

$color-default: #1C2024;

$primary: $indigo !default;
$success: $green !default;
$info: $blue !default;
$warning: $yellow !default;
$danger: $red !default;

$theme-colors: () !default;
// stylelint-disable-next-line scss/dollar-variable-default
$theme-colors: map-merge(
    (
        'primary': $primary,
        'secondary': $gray-600,
        'success': $success,
        'info': $info,
        'warning': $warning,
        'danger': $danger,
        'inverse': #263238,
        'light-danger': #ffebee,
        'light-info': #e1f5fe,
        'light-warning': #fffde7,
        'light-success': #f1f8e9,
    ),
    $theme-colors
);

// ======================================================
// Dark Theme Colors Settings
// ======================================================
$theme-dark-bg-layout: #323743;
$theme-dark-text-color: #b2b9bf !default;
$theme-dark-border-color: rgba(255, 255, 255, 0.2) !default;
$theme-dark-focus-border-color: rgba(255, 255, 255, 0.4) !default;
$theme-dark-card-bg: #272b34;
$theme-dark-bg-light: #3c424e;
$main-body-bg-dark: $gray-900 !default;
$background-alt: #2b2b2b !default;

// ======================================================
// Brand Colors
// ======================================================
$brand-colors: () !default;
$brand-colors: map-merge(
    (
        'facebook': #3b5999,
        'twitter': #1da1f2,
        'googleplus': #dd4b39,
        'instagram': #e4405f,
        'pinterest': #bd081c,
        'youtube': #cd201f,
        'linkedin': #007bb6,
        'slack': #3aaf85,
        'dribbble': #ea4c89,
        'github': #222222,
    ),
    $brand-colors
);

// ======================================================
// Enable optional
// ======================================================
$enable-rounded: true !default;
$enable-shadows: true !default;
$enable-gradients: false !default;

// ======================================================
// Body
// ======================================================
$main-body-bg: #fff !default;
$body-bg: #eef5f9;
$body-color: #67757c !default;

// ======================================================
// Shadow setting
// ======================================================
$box-shadow-sm: 0 0.125rem 0.25rem rgba($black, 0.075) !default;
$box-shadow: 0 0.5rem 1rem rgba($black, 0.08) !default;
$box-shadow-lg: 0 1rem 3rem rgba($black, 0.175) !default;
// Style anchor elements.

$link-hover-decoration: none !default;

// ======================================================
// Typography
// ======================================================
$font-size-base: 0.875rem;
$font-family-sans-serif: 'Roboto', sans-serif !default;
$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-medium: 500 !default;
$font-weight-bold: 700 !default;
$font-weight-bold-xl: 900 !default;
$font-weight-base: $font-weight-light;

// display setting
$display5-size: 3rem !default;
$display6-size: 2.5rem !default;
$display7-size: 2rem !default;
$display5-weight: 300 !default;
$display6-weight: 300 !default;
$display7-weight: 300 !default;

// heading fonts setting
$h1-font-size: 36px !default;
$h2-font-size: 24px !default;
$h3-font-size: 21px !default;
$h4-font-size: 18px !default;
$h5-font-size: 16px !default;
$h6-font-size: 14px !default;
$headings-margin-bottom: (1rem / 2) !default;
$headings-font-weight: 400 !default;
$headings-color: #455a64 !default;

// --------------------------------------------------------------------- //
//              Bootstrap Component override setting
// --------------------------------------------------------------------- //

// Breadcrumbs
$breadcrumb-bg: $body-bg;
$breadcrumb-margin-bottom: 1.5rem;

// Cards
$card-border-width: 0 !default;
$card-border-radius: 4px !default;
$card-bottom-margin: 30px !default;

// Dropdowns
$dropdown-item-padding-x: 1rem !default;
$dropdown-item-padding-y: 0.65rem !default;
$dropdown-border-color: $gray-200;
$dropdown-divider-bg: $gray-100;
$dropdown-box-shadow: 0 50px 100px rgba(50, 50, 93, 0.1), 0 15px 35px rgba(50, 50, 93, 0.15), 0 5px 15px rgba(0, 0, 0, 0.1);

// Buttons
$btn-secondary-border: $gray-300;
$btn-hover-box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
$btn-focus-box-shadow: $btn-hover-box-shadow !default;
$btn-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);

// Tables
$table-bg-accent: $gray-100;
$table-bg-hover: $gray-100;
$table-hover-bg: $gray-100 !default;
$table-cell-padding: 1rem !default;
$table-bg-level: -10 !default;
$table-bg-level2: 1 !default;
$table-bg-level3: -5 !default;

// Components
$component-active-color: $white !default;
$component-active-bg: $themecolor !default;

// Badges
$badge-font-size: 73% !default;
$badge-font-weight: $font-weight-light !default;
$badge-padding-y: 0.35em !default;
$badge-padding-x: 0.7em !default;
$badge-pill-padding-x: 0.7em !default;
$badge-pill-padding-y: 1em !default;

// Forms
$input-group-addon-bg: $gray-100;
$input-border-color: $gray-200;
$input-group-addon-border-color: $gray-200;
$input-btn-focus-color: rgba(0, 0, 0, 0.25) !default;
$input-focus-border-color: rgba(0, 0, 0, 0.25) !default;
$input-btn-focus-box-shadow: none !default;
$input-alternative-box-shadow: 0 1px 3px rgba(50, 50, 93, 0.15), 0 1px 0 rgba(0, 0, 0, 0.02);
$input-focus-alternative-box-shadow: 0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08);
$input-box-shadow: none !default;
$input-focus-box-shadow: none !default;
$custom-select-box-shadow: none !default;

// Border settings
$border-width: 1px !default;
$border-color: $gray-300 !default;
$border-radius: 2px !default;
$border-radius-lg: 4px !default;
$border-radius-sm: 1px !default;

// Progress bars
$progress-height: 6px !default;
$progress-bg: $gray-100;

// Grid breakpoints
$grid-breakpoints: (
    xs: 0,
    sm: 576px,
    md: 768px,
    lg: 992px,
    xl: 1200px,
    xlg: 1600px,
) !default;

// Define the maximum width of `.container` for different screen sizes.
$container-max-widths: (
    sm: 540px,
    md: 720px,
    lg: 960px,
    xl: 1140px,
    xlg: 1600px,
) !default;

// Navbar
$navbar-dark-color: rgba($white, 0.8) !default;
$navbar-dark-hover-color: rgba($white, 1) !default;

// Modal
$modal-content-box-shadow-xs: 0 0.25rem 0.5rem rgba($black, 0.2) !default;
$modal-content-box-shadow-sm-up: 0 0.5rem 1rem rgba($black, 0.2) !default;

// Tabs
$nav-tabs-link-active-bg: $white !default;
$nav-tabs-border-color: $gray-300 !default;
$nav-tabs-border-width: $border-width !default;
$nav-tabs-border-radius: $border-radius !default;
$nav-tabs-link-hover-border-color: $gray-200 $gray-200 $nav-tabs-border-color !default;
$nav-tabs-link-active-color: $gray-700 !default;
$nav-tabs-link-active-bg: $body-bg !default;
$nav-tabs-link-active-border-color: $gray-300 $gray-300 $nav-tabs-link-active-bg !default;

//dinamic width and height classes

@for $i from 1 through 100 {
    .w-#{$i} {
        width: $i * 1% !important;
    }

    .h-#{$i} {
        height: $i * 1% !important;
    }
}
