.font-bold {
    font-weight: $font-weight-bold;
}

.font-normal {
    font-weight: $font-weight-normal;
}

.font-light {
    font-weight: $font-weight-light;
}

.font-medium {
    font-weight: $font-weight-medium;
}

.font-bold-xl {
    font-weight: $font-weight-bold-xl;
}

.font-weight-subtitle {
    font-weight: 600 !important;
    font-size: 20px !important;
    color: #6c757d !important;
}
